import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';
import { InfoBox } from "../Various/InfoBox";
import { Event } from "../../../types/Event";
import { useMemo } from "react";
import { buildLink } from "../../services/events";

export const EventInfoBox = ({ event }: {
    event: Event
}) => {
    const { t } = useTranslation(['common', 'events']);

    let endDate = useMemo(() => dayjs(event.event_end_date), [event.event_end_date]);
    let startDate = useMemo(() => dayjs(event.event_start_date), [event.event_start_date]);

    const isActive = useMemo(() => {
        const now = dayjs();

        return now.isBefore(endDate) && now.isAfter(startDate);
    }, [endDate, startDate]);

    const eventLink = useMemo(() => buildLink(event), [event]);

    return (
        <InfoBox
            title={event.name}
            subtitle={startDate.format('DD-MM-YYYY HH:mm')}
            image={event.img}
            btnProps={{
                label: t('common:read_more'),
                onClick: eventLink
            }}
            extraClasses="relative"
            description={event.description}
        >
            {isActive && <div className="absolute top-2 left-2 tracking-widest inline-block animate-[pulse_3s_ease-in-out_infinite] text-sm font-bold rounded leading-none p-2 bg-red-500 text-white">
                LIVE
            </div>}

        </InfoBox>
    )
}
