import { Container } from "../components/UI/Container";
import { TopBar } from "../components/Nav/TopBar";

export const PrivacyPolicyContainer = () => {
  return (
    <>
      <TopBar />
      <Container className="pt-10 pb-10">
        <article className="prose">
          <h1 className="break-words">Privatlivsoplysninger og databehandling</h1>
          <p>Alle de services vi bruger, er godkendte GDPR udbydere</p>
          <p>Det er kun Init-Together der har adgang servere og backup.</p>
          <h2>Adgang til oplysninger vedr. Event, deltagere, donationer m.m.:</h2>
          <p>
            <span className="underline-offset-4 underline font-medium">Det er kun NGOen, arrangøren af eventet, og Init-Together der har adgang til dine data</span>, og den bliver ikke videregivet til andre virksomheder (Med undtagelse af de betalingsudbydere, som behandler donationerne).
          </p>
          <p>
            Det er kun de oplysninger, der er nødvendige for at afvikle et event, der bliver videregivet til NGOen og arrangøren.
          </p>
          <p>
            Du kan til enhver en tid henvende dig til arrangøren for at få slettet dine data.
          </p>
          <h2>Opbevaring af persondata</h2>
          <p>
            En måned efter løbet bliver alle dine persondata omdannet til random data. Dette vil sige at telefonnumre slettes; og dit navn bliver ændret til et tilfældigt generet navn. Dette gøres for at bevare statistikken over de tidligere løb, men uden dine persondata.
          </p>
          <p>
            Det betyder helt konkret at vi gemmer din data i 2 måneder, grundet den daglige backup der bliver gemt i en måned.
          </p>
          <p>
            Du kan til enhver tid skrive til <a href="mailto:info@init-together.dk" target="_blank" rel="noreferrer">info@init-together.dk</a> for at få overblik over hvad data vi har på dig, og få det fjernet med det samme. Dog kan data fra backuppen ikke fjernes, før den automatisk bliver slettet efter en måned.
          </p>
          <h2>Servere og backup</h2>
          <h3>Hetzner hosting</h3>
          <p>Alle vores servere er hostet hos Hetzner og drives i et datacenter i Finland.</p>
          <h3>Backblaze</h3>
          <p>
            Vi tager daglig backup af vores database og billeder på siden. Backuppen bliver lagt på Backblaze hvor det er krypteret, og med en timelock der gør at bliver slettet efter 30 dage.
          </p>

          <h3>Postmark</h3>
          <p>
            Vi bruger postmark til at sende mails ud med, så derved sender vi din mailadresse over til dem når vi sender mails. En kopi af de udsendte emails er gemt hos dem i 41 dage hvorefter de bliver slettet.
          </p>
          <h3>Stripe</h3>
          <p>
            Vi bruger stripe til at opkræve betalinger via kreditkort, så derved sender vi din mailadresse, navn og telefonnummer over til dem. De oplysninger er gemt hos dem i 6 måneder hvorefter de bliver slettet.
          </p>
          <h3>Vipps MobilePay</h3>
          <p>
            Når et event er færdig, og du opkræves af Vipps MobilePay, sender vi dit mobilnummer og navn igennem Vipps MobilePay.
          </p>
          <h3>Bankoverførsel</h3>
          <p>
            Efter løbet får LoveSpring en liste over de sponsoere der har valgt bankoverførsel, med tlf, navn og email så de selv kan holde styr på overførslerne.
          </p>
          <h2>LoveSpring</h2>
          <p>
            Da alle penge går direkte til LoveSpring, bruges LoveSprings konto hos Vipps MobilePay og Stripe, hvilket betyder de har adgang til oplysningerne som vi sender til de tjenester.
            <br />
            LoveSpring behandler deres data, i overensstemmelse med deres privatlivspolitik.
          </p>
          <h2>Cookies</h2>
          <p>Vi anvender ingen cookies, udover teknisk funktionelle cookies.</p>
          <h2>Donationer</h2>
          <p>Er ikke bindende og det er derved 100% frivilligt, om man efter eventet vil betale det beløb, der opgøres ud fra deltagerens præstation.</p>
          <p>Init-Together har intet med pengene at gøre, da de går direkte fra Vipps MobilePay eller andre betalingsmetoder, ind på den godkendte NGOs bankkonto.</p>
          <h2>Projekter</h2>
          <p>Init-Together har ingen indflydelse på hvorledes NGOen forvalter donationerne. Det er en sag mellem løbsafvikler og NGO'en at bestemme hvorledes donationerne bruges.</p>
        </article>
      </Container>
    </>
  );
}